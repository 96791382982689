import React from "react";
import Layout from "../../layout/Layout";


const Survey = () => {
    return (
        <Layout>
        <div>
        <h1>Survey</h1>
        </div>
        </Layout>
    );
    }


export default Survey;