import React from "react";

const ExamSubmitConformaction = () =>{
    return(
        <div>
            <h1>Are you sure you want to submit the exam?</h1>
            <button>Yes</button>
            <button>No</button>
        </div>
    )
}
export default ExamSubmitConformaction;